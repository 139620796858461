import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {DataGrid} from "@mui/x-data-grid";
import {useIntl} from "react-intl";
import {Box, IconButton, Typography} from "@material-ui/core";
import {openDialog, resetCreditNotesState} from "../../../../redux/reducers/Treasury/creditNotes";
import {parseNumberToMoney} from "../../../../utils/parsers";
import ContainedButton from "../../../Common/Buttons/ContainedButton";
import {parseDateToDateString} from "../../../../utils/dates";
import ShowIcon from "@material-ui/icons/Visibility";
import FilterToolbar from "../../../Common/Tables/DataGrid/FilterToolbar";
import {
    cancelSentCreditNote,
    fetchAllCreditNotes,
    getAndSetCreditNoteDetails,
    sendSunatSelectedCreditNotes
} from "../../../../redux/thunks/Treasury/creditNotes";
import {dialogsList} from "../constants";
import DownloadMenu from "../../common/DownloadMenu";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import {Link, useRouteMatch} from "react-router-dom";
import DetailsDialog from "./DetailsDialog";
import DeleteIcon from "@material-ui/icons/Delete";

const Table = () => {
    const {path} = useRouteMatch();
    const dispatch = useDispatch();
    const creditNotes = useSelector(({treasury}) => treasury.creditNotes.creditNotes);
    ;
    const [selectedCreditNotes, setSelectedCreditNotes] = React.useState([]);
    const intl = useIntl();
    React.useEffect(() => {
        dispatch(fetchAllCreditNotes());
        return () => {
            dispatch(resetCreditNotesState());
        };
    }, []);
    const handleShowDetails = (row) => {
        dispatch(openDialog(dialogsList.CREDIT_NOTE_DETAILS));
        dispatch(getAndSetCreditNoteDetails({creditNote: row}));
    }


    const handleSentSunat = () => {
        dispatch(sendSunatSelectedCreditNotes({selectedCreditNotes}))
            .unwrap()
            .finally(() => {
                setSelectedCreditNotes([]);
            });
    }

    const handleCancelCreditNote = (creditNoteId) => {
        dispatch(cancelSentCreditNote({creditNoteId}))
            .unwrap()
            .finally(() => {
                setSelectedCreditNotes([]);
            });
    }
    const columns = [
        {
            field: 'id',
            hide: true,
            filterable: false,
        },
        {
            field: 'creationDate',
            headerName: intl.messages['date'],
            valueFormatter: ({value}) => parseDateToDateString(value),
            width: 200,
            filterable: false,
        },
        {
            field: 'billNumber',
            headerName: intl.messages['num'],
            width: 200,
            filterable: false,
        },
        {
            field: 'clientName',
            headerName: intl.messages['client'],
            width: 200,
        },
        {
            field: 'subtotal',
            headerName: intl.messages['subtotal'],
            valueFormatter: ({value}) => `${parseNumberToMoney(value)}`,
            width: 200,
            filterable: false,
        },
        {
            field: 'total',
            headerName: intl.messages['total'],
            valueFormatter: ({value}) => `${parseNumberToMoney(value)}`,
            width: 200,
            filterable: false,
        },
        {
            field: 'sunatSent',
            headerName: intl.messages['sunat.accepted'],
            valueFormatter: ({value}) => value ? intl.messages['yes'] : intl.messages['no'],
            width: 150,
        },
        {
            field: 'creditNoteReference',
            headerName: intl.messages['reference'],
            width: 150,
        },
        {
            field: 'state',
            headerName: intl.messages['state'],
            width: 200,
            renderCell: ({value, row}) =>
                (<Typography
                    style={{
                        color: '#fff',
                        backgroundColor: row.stateColor ?? '#000',
                        padding: '4px 8px',
                    }}
                >
                    {value}
                </Typography>)

        },
        {
            field: 'actions',
            headerName: intl.messages["details"],
            width: 180,
            type: 'actions',
            renderCell: ({row}) => (
                <>
                    <IconButton onClick={() => handleShowDetails(row)}>
                        <ShowIcon/>
                    </IconButton>
                    {row.sunatSent
                        && <DownloadMenu bill={row}/>
                    }
                    {row.isNullable
                        && <IconButton onClick={() => handleCancelCreditNote(row.id)}>
                            <DeleteIcon />
                        </IconButton>
                    }
                </>
            ),
        }
    ]
    return (
        <>
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    gap: '1rem',
                    marginBottom: '1rem',
                    width: '100%',
                }}
            >
                <Link
                    to={`${path}/add`}
                >
                    <ContainedButton
                        text={<IntlMessages id="add"/>}
                        color="primary"
                    />
                </Link>
                <ContainedButton
                    text={intl.messages['send.sunat']}
                    color='primary'
                    onClick={handleSentSunat}
                    disabled={selectedCreditNotes?.some(creditNoteId => creditNotes.find(creditNote => creditNote.id === creditNoteId).sunatSent)}
                />
            </Box>
            <DataGrid
                columns={columns}
                rows={creditNotes}
                style={{
                    height: '70vh'
                }}
                disableColumnMenu
                disableSelectionOnClick
                checkboxSelection
                selectionModel={selectedCreditNotes}
                onSelectionModelChange={(newSelection) => {
                    setSelectedCreditNotes(newSelection);
                }}
                components={{Toolbar: FilterToolbar}}
            />
            <DetailsDialog/>
        </>
    );
};

export default Table;