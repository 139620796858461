import React from 'react';
import useForm from "../../../../../hooks/Common/useForm";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import ServiceSelect from "./ServiceSelect";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import TaxSelect from "../../TaxSelect";
import MoneyInput from "../../../../Common/Forms/MoneyInput";

const INIT_DATA = {
    id: '',
    serviceId: '',
    serviceName: '',
    description: '',
    quantity: 1,
    unitPrice: 1,
    discount: 0,
    finalUnitPrice: 1,
    tax: '',
    taxName: '',
    percentage: 0,
    detailId: '',
}

const ServiceForm = ({id, service = {}, isConcept = false, onSubmit}) => {
    const {formData, setFormData, handleSubmit, setValues} = useForm(INIT_DATA);
    React.useEffect(() => {
        service.id && setValues(service);
    }, []);
    const handleChangeTotal = (value) => {
        setValues({
            finalUnitPrice: value,
            unitPrice: (value / (1 + formData.percentage / 100)).toFixed(2),
        });
    }

    const handleChangeUnitPrice = (value) => {
        setValues({
            finalUnitPrice: (value * (1 + formData.percentage / 100)).toFixed(2),
            unitPrice: value,
        });
    }

    const handleChangeTax = ({value, taxName, percentage}) => {
        setValues({
            tax: value,
            percentage,
            taxName,
            unitPrice: formData.unitPrice,
            finalUnitPrice: (formData.unitPrice * (1 + percentage / 100)).toFixed(2),
        });
    }

    const handleChangeService = ({value, name, unitPrice, taxId}) => {
        setValues({
            serviceId: value,
            serviceName: name,
            tax: taxId,
            unitPrice: unitPrice,
            finalUnitPrice: (unitPrice * (1 + formData.percentage / 100)).toFixed(2),
        });
    }

    return (
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={2}>
                <Grid item xs={12} md={6}>
                    {isConcept
                        ? <OutlinedInput
                            name='serviceName'
                            label={<IntlMessages id='concept' />}
                            value={formData.serviceName}
                            onChange={value => setFormData('serviceName', value)}
                            fullWidth
                            required
                        />
                        : <ServiceSelect
                            name='serviceId'
                            label={<IntlMessages id='service' />}
                            value={formData.serviceId}
                            onChange={newValues => handleChangeService(newValues)}
                            required
                        />
                    }
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        name='description'
                        label={<IntlMessages id='observations' />}
                        value={formData.description}
                        onChange={value => setFormData('description', value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        name='quantity'
                        type='number'
                        label={<IntlMessages id='quantity' />}
                        value={formData.quantity}
                        onChange={value => setFormData('quantity', value)}
                        fullWidth
                        inputProps={{min: 1}}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        name='unitPrice'
                        type='number'
                        label={<IntlMessages id='unit.price' />}
                        value={formData.unitPrice}
                        onChange={value => handleChangeUnitPrice(value)}
                        fullWidth
                        inputProps={{min: 1, step: 0.01}}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <MoneyInput
                        name='finalUnitPrice'
                        label={<IntlMessages id='final.unit.price' />}
                        value={formData.finalUnitPrice}
                        onChange={value => handleChangeTotal(value)}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        name='discount'
                        type='number'
                        label={<IntlMessages id='discount' />}
                        value={formData.discount}
                        onChange={value => setFormData('discount', value)}
                        fullWidth
                        inputProps={{min: 0}}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TaxSelect
                        value={formData.tax}
                        onChange={newValues => handleChangeTax(newValues)}
                        required
                        initValue={service?.tax || null}
                    />
                </Grid>
            </GridContainer>
        </form>
    );
};

export default ServiceForm;